import { Component } from '@angular/core';
import { DiscordAuthService } from '../../shared/services/discord-auth.service';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
  selector: 'app-discord-social-auth',
  standalone: true,
  imports: [ProgressSpinnerModule],
  templateUrl: './discord-social-auth.component.html',
})
export class DiscordSocialAuthComponent {
  constructor(
    private discordAuthService: DiscordAuthService,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    if (window.location.search.includes('code')) {
      const code: string = this.discordAuthService.handleRedirectCallback();

      const authAction = localStorage.getItem('socialAuthAction');

      switch (authAction) {
        case 'signin': {
          this.authService.discordSocialLogin(code);
          break;
        }
        case 'signup': {
          const signupDataRaw = localStorage.getItem('signupData');
          if (!signupDataRaw) return;
          const signupData: { username: string; developerTag: string, registeredAddress: string } =
            JSON.parse(signupDataRaw);

          this.authService.discordSocialSignUp({ ...signupData, code });
          break;
        }
        default: {
          console.error(
            'Error: Unknown Social Authorization Type (google-social-auth.component.ts)'
          );
        }
      }
    }
  }
}
