import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, tap } from 'rxjs';
import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { MessageService } from 'primeng/api';

export const AuthGuard: CanActivateFn = (
  activatedRoute: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean> => {
  const authService: AuthService = inject(AuthService);
  const messageService: MessageService = inject(MessageService);
  const router: Router = inject(Router);

  return authService.isLoggedIn$.pipe(
    tap((loggedIn: boolean) => {
      if (!loggedIn) {
        messageService.add({
          severity: 'warning',
          detail: "Isn't logged in",
        });
        router.navigateByUrl('/auth/login');
      }
    })
  );
};
