import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { Router } from '@angular/router';
import {
  AccessToken,
  SignUp,
} from '../../tools/interfaces/response.interfaces';
import { environment } from '../../../environments/environment';
import { USER_ROLE } from '../../tools/constants/auth.constants';
import { GamesService } from './games.service';

@Injectable({
  providedIn: 'root',
})
// TODO: add UserService
export class AuthService {
  private accessToken: string | null = null;
  private refreshToken: string | null = null;

  //TODO: consider usage sessionStorage
  isAdmin$: BehaviorSubject<boolean>;
  isLoggedIn$: BehaviorSubject<boolean>;

  get isLoggedIn(): boolean {
    return this.isLoggedIn$.value;
  }

  getAccessToken(): string | null {
    return this.accessToken;
  }

  getRefreshToken(): string | null {
    return this.refreshToken;
  }

  setAccessToken(token: string): void {
    this.accessToken = token;
    localStorage.setItem('accessToken', token);
  }

  setRefreshToken(token: string): void {
    this.refreshToken = token;
    localStorage.setItem('refreshToken', token);
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private gamesService: GamesService
  ) {
    // TODO: consider renaming
    const previouslyAuthenticated: boolean =
      this.retreiveTokensFromLocalStorage();
    this.isLoggedIn$ = new BehaviorSubject<boolean>(previouslyAuthenticated);
    this.isAdmin$ = new BehaviorSubject<boolean>(true);
  }

  getCurrentUserInfo(): Observable<{ email: string; username: string }> {
    return this.http.get<{ email: string; username: string }>(
      `${environment.devportalApi}/api/user/me`
    );
  }

  updateUserPassword(): Observable<{ success: boolean }> {
    return this.http.put<{ success: boolean }>(
      `${environment.devportalApi}/api/user/password/update`,
      {}
    );
  }

  private retreiveTokensFromLocalStorage(): boolean {
    const accessToken: string = localStorage.getItem('accessToken') || '';
    const refreshToken: string = localStorage.getItem('refreshToken') || '';

    if (!refreshToken || !accessToken) return false;

    this.setAccessToken(accessToken);
    this.setRefreshToken(refreshToken);

    return true;
  }

  refreshAccessToken(): Observable<AccessToken> {
    const refreshToken: string | null = this.getRefreshToken();

    return this.http
      .post<AccessToken>(`${environment.devportalApi}/api/auth/token/refresh`, {
        refreshToken,
      })
      .pipe(
        tap((tokenResponse: AccessToken) => {
          const { access_token: accessToken, refresh_token: refreshToken } =
            tokenResponse;
          this.setAccessToken(accessToken);
          this.setRefreshToken(refreshToken);
        })
      );
  }

  async googleSocialLogin(code: string): Promise<void> {
    this.getTokenByGoogleCodeRequest(code).subscribe({
      next: (response: AccessToken) => {
        this.handleAccessTokenResponse(response);
      },
      error: () => {
        this.router.navigateByUrl('auth/login');
      },
    });
  }

  async discordSocialLogin(code: string): Promise<void> {
    this.getTokenByDiscordCodeRequest(code).subscribe({
      next: (tokenResponse: AccessToken) => {
        this.handleAccessTokenResponse(tokenResponse);
      },
      error: () => {
        this.router.navigateByUrl('auth/login');
      },
    });
  }

  async googleSocialSignUp(signupData: {
    code: string;
    username: string;
    developerTag: string;
    registeredAddress: string;
  }): Promise<void> {
    const requestBody = {
      code: signupData.code,
      username: signupData.username,
      displayName: signupData.developerTag,
      registeredAddress: signupData.registeredAddress,
    };

    this.http
      .post<SignUp>(
        `${environment.devportalApi}/api/user/sign-up/google`,
        requestBody
      )
      .subscribe({
        next: (authResponse: SignUp) => {
          this.handleAccessTokenResponse(authResponse.token);
        },
        error: () => {
          this.router.navigateByUrl('auth/login');
        },
      });
  }

  async discordSocialSignUp(signupData: {
    code: string;
    username: string;
    developerTag: string;
    registeredAddress: string;
  }): Promise<void> {
    const requestBody = {
      code: signupData.code,
      username: signupData.username,
      displayName: signupData.developerTag,
      registeredAddress: signupData.registeredAddress,
    };

    this.http
      .post<SignUp>(
        `${environment.devportalApi}/api/user/sign-up/discord`,
        requestBody
      )
      .subscribe({
        next: (authResponse: SignUp) => {
          this.handleAccessTokenResponse(authResponse.token);
        },
        error: () => {
          this.router.navigateByUrl('auth/login');
        },
      });
  }

  basicLogin(signinData: { emailOrUsername: string; password: string }): void {
    this.getAccessTokenRequest({
      emailOrUsername: signinData.emailOrUsername,
      password: signinData.password,
    }).subscribe({
      next: (tokenResponse: AccessToken) => {
        this.handleAccessTokenResponse(tokenResponse);
      },
    });
  }

  basicSignUp(signupData: {
    email: string;
    password: string;
    username: string;
    developerTag: string;
    registeredAddress: string;
  }): void {
    const requestBody = {
      email: signupData.email,
      password: signupData.password,
      username: signupData.username,
      displayName: signupData.developerTag,
      registeredAddress: signupData.registeredAddress,
    };

    this.http
      .post<SignUp>(
        `${environment.devportalApi}/api/user/sign-up/password`,
        requestBody
      )
      .subscribe({
        next: (authResponse: SignUp) => {
          this.handleAccessTokenResponse(authResponse.token);
        },
      });
  }

  resetPasswordRequest(email: string): Observable<{ success: boolean }> {
    return this.http.post<{ success: boolean }>(
      `${environment.devportalApi}/api/user/password/request-reset`,
      {
        email,
      }
    );
  }

  resetPasswordConfirm(
    token: string,
    password: string
  ): Observable<{ success: boolean }> {
    return this.http.post<{ success: boolean }>(
      `${environment.devportalApi}/api/user/password/confirm-reset`,
      {
        token,
        password,
      }
    );
  }

  signOut(): void {
    this.accessToken = null;
    this.refreshToken = null;
    localStorage.setItem('accessToken', '');
    localStorage.setItem('refreshToken', '');
    this.isLoggedIn$.next(false);
    this.isAdmin$.next(false);
    this.router.navigateByUrl('auth/login');
  }

  private getAccessTokenRequest(authData: {
    emailOrUsername: string;
    password: string;
  }): Observable<AccessToken> {
    return this.http.post<AccessToken>(
      `${environment.devportalApi}/api/auth/token/password`,
      authData
    );
  }

  private getTokenByDiscordCodeRequest(code: string): Observable<AccessToken> {
    return this.http.post<AccessToken>(
      `${environment.devportalApi}/api/auth/token/discord`,
      {
        code,
      }
    );
  }

  private getTokenByGoogleCodeRequest(code: string): Observable<AccessToken> {
    return this.http.post<AccessToken>(
      `${environment.devportalApi}/api/auth/token/google`,
      {
        code,
      }
    );
  }

  private handleAccessTokenResponse(tokenResponse: AccessToken): void {
    const { access_token: accessToken, refresh_token: refreshToken } =
      tokenResponse;

    this.setAccessToken(accessToken);
    this.setRefreshToken(refreshToken);
    this.isLoggedIn$.next(true);
    this.getUserRolesByToken().subscribe((userRoles) => {
      if (userRoles.includes(USER_ROLE.ADMIN)) {
        this.isAdmin$.next(true);
        this.router.navigateByUrl('/admin/gameslist');
      } else {
        this.gamesService.getUsersGames().subscribe((games) => {
          if (games.length) {
            this.router.navigateByUrl('/app/home');
          } else {
            this.router.navigateByUrl('/app/add-first-game');
          }
        });
      }
    });
  }

  private getUserRolesByToken(): Observable<USER_ROLE[]> {
    return this.http.get<USER_ROLE[]>(
      `${environment.devportalApi}/api/auth/roles`
    );
  }
}
