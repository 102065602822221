import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { AuthService } from '../../shared/services/auth.service';
import { MessageService } from 'primeng/api';
import { ValidationMessageDirective } from '../../shared/directives/validation-message.directive';
import { NOTIFICATION } from '../../tools/constants/notification.constants';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ValidationMessageDirective,
    ButtonModule,
    InputTextModule,
    RouterModule,
  ],
})
export class ForgotPasswordComponent {
  restorePasswordForm: FormGroup = this.fb.group({
    email: [null, [Validators.required, Validators.email]],
  });

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private messageService: MessageService,
    private router: Router
  ) {}

  onSubmit(): void {
    if (!this.restorePasswordForm.valid) return;

    const email: string = this.restorePasswordForm.get('email')?.value;

    this.authService.resetPasswordRequest(email).subscribe(() => {
      this.messageService.add({
        severity: NOTIFICATION.SUCCESS,
        summary: 'Success',
        detail: 'Password reset request was successfuly sent!',
      });
      this.router.navigateByUrl('auth/login');
    });
  }
}
