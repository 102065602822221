import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, fromEvent, merge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  isOnline$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    navigator.onLine
  );

  constructor(private messageService: MessageService) {
    this.initializeNetworkStatus();
  }

  get isOnline(): boolean {
    return this.isOnline$.value;
  }

  get onlineStatus$(): Observable<boolean> {
    return this.isOnline$.asObservable();
  }

  private initializeNetworkStatus(): void {
    const online$: Observable<Event> = fromEvent(window, 'online');
    const offline$: Observable<Event> = fromEvent(window, 'offline');

    merge(online$, offline$)
      .pipe(map(() => navigator.onLine))
      .subscribe((status: boolean) => {
        this.isOnline$.next(status);

        this.notifyNetworkStatus(status);
      });
  }

  private notifyNetworkStatus(networkStatus: boolean): void {
    networkStatus
      ? this.messageService.add({
          severity: 'success',
          detail: 'Connection restored!',
        })
      : this.messageService.add({
          severity: 'error',
          summary: 'Network error',
          detail: 'There are some problems with internet connection',
        });
  }
}
