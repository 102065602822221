<form
  [formGroup]="signinForm"
  (ngSubmit)="onSubmit()"
  class="form m-auto w-400px"
>
  <div class="mb-6">
    <h2 class="text-4xl mb-2 font-medium">Sign in</h2>
    <span class="text-md font-normal text-gray-400">
      Don't have an account?
    </span>
    <a
      routerLink="/auth/sign-up"
      class="text-primary text-md font-normal no-underline hover:text-primary-300"
      >Sign up</a
    >
  </div>

  <div class="mb-3 relative">
    <label for="email-or-username" class="block mb-1 text-md text-gray-200">
      Email or Username
    </label>
    <input
      id="email-or-username"
      type="text"
      placeholder="Enter your email or username"
      pInputText
      class="w-full autofill-main-black"
      formControlName="emailOrUsername"
      [appValidationMessage]="signinForm.controls['emailOrUsername']"
      [validationMessages]="{
        required: 'Email or Username is required',
        pattern: 'Invalid format for Email or Username'
      }"
    />
  </div>

  <div class="mb-3 relative">
    <label for="password" class="block mb-1 text-md text-gray-200">
      Password
    </label>
    <p-password
      id="password"
      class="p-fluid"
      placeholder="Enter your password"
      formControlName="password"
      [feedback]="false"
      [toggleMask]="true"
      [appValidationMessage]="signinForm.get('password')"
      [validationMessages]="{
        required: 'Password is required',
        pattern: 'Invalid format for password'
      }"
    />
  </div>

  <a
    class="text-gray-400 block mb-5 no-underline text-md hover:text-gray-200 w-fit"
    routerLink="/auth/forgot-password"
  >
    Forgot password?
  </a>

  <p-button
    type="submit"
    label="Sign in"
    severity="primary"
    [disabled]="signinForm.invalid"
    class="p-fluid mb-5 block"
  ></p-button>

  <span class="text-gray-400 flex gap-3 align-items-center mb-5 text-md">
    <hr class="border-none border-top-1 border-gray-700 flex-1" />
    <span>Or continue with</span>
    <hr class="border-none border-top-1 border-gray-700 flex-1" />
  </span>

  <div class="flex gap-2">
    <p-button
      severity="secondary"
      class="p-fluid w-full"
      (onClick)="googleSocialSignin()"
    >
      <div class="flex align-items-center justify-content-center w-full gap-3">
        <img src="../../../assets/icons/social/google.svg" alt="" />

        <span class="font-bold">Google</span>
      </div>
    </p-button>
    <p-button
      severity="secondary"
      class="p-fluid w-full"
      (onClick)="discordSocialSignin()"
    >
      <div class="flex align-items-center justify-content-center w-full gap-3">
        <img src="../../../assets/icons/social/discord.svg" alt="" />

        <span class="font-bold">Discord</span>
      </div>
    </p-button>
  </div>
</form>
