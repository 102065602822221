<div class="flex h-screen w-screen p-3">
  <div class="flex flex-column flex-1 justify-content-center">
    <div
      class="justify-self-center w-400px align-self-end m-auto"
    >
      <div class="mb-6">
        <button
          type="button"
          class="flex justify-content-center align-items-center w-min cursor-pointer gap-1 p-2 border-round-md text-gray-100 border-1 border-gray-700 mb-8 transition-colors transition-duration-200 hover:border-white"
          [ngStyle]="{
            'line-height': '100%',
            'background-color': 'transparent'
          }"
          (click)="onMoveBackClick()"
        >
          <svg
            width="5"
            height="10"
            viewBox="0 0 5 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.183069 5.45387C-0.0610235 5.20283 -0.0610235 4.79516 0.183069 4.54413L3.93234 0.688273C4.17643 0.437241 4.57284 0.437241 4.81693 0.688273C5.06102 0.939305 5.06102 1.34698 4.81693 1.59801L1.50898 5L4.81498 8.40199C5.05907 8.65302 5.05907 9.06069 4.81498 9.31173C4.57088 9.56276 4.17448 9.56276 3.93038 9.31173L0.181117 5.45587L0.183069 5.45387Z"
              fill="#D3D3EE"
            />
          </svg>

          Back
        </button>

        <h2 class="text-3xl mb-5 font-medium">Join the GAIMIN referral program and invite new users to the platform</h2>

        <p class="text-md font-normal mb-3">
          You'll earn 30% of their rewards once they start earning on GAIMIN.
          Sign up for your developer account today!
        </p>

        <p class="text-md font-normal">
          To be eligible for these rewards, you need to monetize for at least 10 hours per week.
        </p>
      </div>

      <p-button
        label="Connect"
        class="p-fluid mb-5 block"
        (onClick)="connectToReferal()"
      ></p-button>
    </div>
  </div>

  <div class="flex-1 h-full">
    <div
      class="bg-gray-1000 text-center border-round-xl flex flex-column h-full justify-content-center align-items-center gap-6"
    >
      <img src="./assets/illustrations/referal.png" alt="" class="w-full" />
      <div
        class="flex flex-column justify-content-center align-items-center gap-3"
        [ngStyle]="{ 'max-width': '500px' }"
      >
        <h2 class="text-5xl font-medium mb-2">
          Connect your account to GAIMIN platform
        </h2>
      </div>
    </div>
  </div>
</div>

<app-loader></app-loader>
