export enum SIGNUP_TYPE {
  GOOGLE = 'google',
  DISCORD = 'discord',
  BASIC = 'basic',
}

export enum USER_ROLE {
  ADMIN = 'ROLE_ADMIN',
  USER = 'ROLE_USER',
}
