<div
    class="flex justify-content-between px-4 py-3 border-bottom-1 border-gray-800"
  >
    <div>
      <img
        src="assets/icons/app-icon.svg"
        alt="App Icon"
        class="w-5rem cursor-pointer"
        routerLink="/app/home"
      />
    </div>
    <div class="flex gap-4">
      <img
        src="./assets/icons/dollar-icon.svg"
        alt=""
        class="cursor-pointer"
        pTooltip="Referal"
        tooltipPosition="bottom"
        positionTop="10"
        [tooltipOptions]="tooltipOptions"
        (click)="onReferalClick()"
      />

      <img
        src="./assets/icons/question-icon.svg"
        alt=""
        class="cursor-pointer"
        pTooltip="Support"
        [tooltipOptions]="tooltipOptions"
        (click)="openSupportDialog()"
      />

      <img
        src="./assets/icons/account-icon.svg"
        alt=""
        class="cursor-pointer"
        pTooltip="Account"
        [tooltipOptions]="tooltipOptions"
        (click)="op.toggle($event)"
      />

      <p-overlayPanel #op>
        <div class="flex flex-column gap-3">
          <span
            class="cursor-pointer hover:text-gray-300 transition-duration-100"
            (click)="onOpenSettings()"
            >Account settings</span
          >
          <span
            class="cursor-pointer hover:text-gray-300 transition-duration-100"
            (click)="onSignOut()"
            >Sign out</span
          >
        </div>
      </p-overlayPanel>
    </div>
  </div>