import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { environment } from '../../../../environments/environment';
import { MessageService, PrimeTemplate } from 'primeng/api';
import { NOTIFICATION } from '../../../tools/constants/notification.constants';

@Component({
  selector: 'app-change-password-dialog',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    PasswordModule,
    ButtonModule,
    PrimeTemplate,
  ],
  templateUrl: './change-password-dialog.component.html',
})
export class ChangePasswordDialogComponent {
  newPasswordForm: FormGroup = this.fb.group({
    currentPassword: ['', [Validators.required]],
    newPassword: ['', [Validators.required]],
    repeatNewPassword: ['', [Validators.required]],
  });

  get doPasswordsMatch(): boolean {
    return (
      this.newPasswordForm.get('newPassword')?.value ===
      this.newPasswordForm.get('repeatNewPassword')?.value
    );
  }

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private fb: FormBuilder,
    private http: HttpClient,
    private messageService: MessageService
  ) {
    this.config.styleClass = 'bg-gray-1000 ';
    this.config.contentStyle = {
      width: '500px',
      'background-color': '#18181E',
    };
    this.config.header = 'Change password';
  }

  onUpdatePassword(): void {
    if (this.newPasswordForm.invalid) return;

    const { currentPassword, newPassword } = this.newPasswordForm.value;
    this.http
      .put(`${environment.devportalApi}/api/user/password/update`, {
        oldPassword: currentPassword,
        newPassword,
      })
      .subscribe(() => {
        this.messageService.add({
          severity: NOTIFICATION.SUCCESS,
          summary: 'Success',
          detail: 'The password has been successfully changed',
        });

        this.ref.close();
      });
  }

  onCancel(): void {
    this.ref.close();
  }
}
