import { Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AdminService } from '../../services/admin.service';

@Component({
  selector: 'app-reject-game',
  standalone: true,
  imports: [ButtonModule],
  templateUrl: './reject-game-dialog.component.html',
})
export class RejectGameComponentDialog {
  rejectGame = () => {};

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
  ) {
    this.config.styleClass = 'bg-gray-1000 w-600px';
    this.config.header = 'Reject game';
    this.rejectGame = this.config.data.rejectGame;
  }

  onGameReject() {
    this.rejectGame();
    this.ref.close();
  }

  onCancel() {
    this.ref.close();
  }
}
