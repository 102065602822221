import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SupportRequest } from '../../tools/dto/request.dto';

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  constructor(private http: HttpClient) { }

  sendSupportRequest(request: SupportRequest) {
    return this.http.post(`${environment.devportalApi}/api/support/email`, request);
  }
}
