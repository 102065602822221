import { Component } from '@angular/core';
import { GoogleAuthService } from '../../shared/services/google-auth.service';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@Component({
  selector: 'app-google-social-auth',
  standalone: true,
  imports: [ProgressSpinnerModule],
  templateUrl: './google-social-auth.component.html',
})
export class GoogleSocialAuthComponent {
  constructor(
    private googleAuthService: GoogleAuthService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    if (window.location.search.includes('code')) {
      const code: string = this.googleAuthService.handleRedirectCallback();

      const authAction = localStorage.getItem('socialAuthAction');

      switch (authAction) {
        case 'signin': {
          this.authService.googleSocialLogin(code);
          break;
        }
        case 'signup': {
          const signupDataRaw = localStorage.getItem('signupData');
          if (!signupDataRaw) return;
          const signupData: {
            username: string;
            developerTag: string;
            registeredAddress: string;
          } = JSON.parse(signupDataRaw);

          this.authService.googleSocialSignUp({ ...signupData, code });
          break;
        }
        default: {
          console.error(
            'Error: Unknown Social Authorization Type (google-social-auth.component.ts)'
          );
        }
      }
    }
  }
}
