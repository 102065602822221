import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GoogleAuthService {
  private readonly CLIENT_ID: string =
    '263013827022-tlbjbljoqrn0onq4fc9han9gk16pssga.apps.googleusercontent.com';
  private readonly REDIRECT_URI: string = `${environment.devportalUrl}/login/oauth2/code/google`;
  private readonly SCOPES: string[] = ['openid', 'email'];

  constructor() {}

  private generateCsrfToken(): string {
    return Math.random().toString(36).substring(2);
  }

  // TODO: Refactor
  handleGoogleAuthorization(): void {
    const csrfToken: string = this.generateCsrfToken();

    const params: HttpParams = new HttpParams()
      .set('client_id', this.CLIENT_ID)
      .set('redirect_uri', this.REDIRECT_URI)
      .set('response_type', 'code')
      .set('scope', this.SCOPES.join(' '))
      .set('state', csrfToken)
      .set('access_type', 'offline');

    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?${params.toString()}`;

    sessionStorage.setItem('googleCsrfToken', csrfToken);

    window.location.href = authUrl;
  }

  handleRedirectCallback(): string {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    const returnedCsrfToken = params.get('state');
    const storedCsrfToken = sessionStorage.getItem('googleCsrfToken');

    if (!code || returnedCsrfToken !== storedCsrfToken) {
      console.error('Invalid CSRF Token or Code not returned');
      throw new Error('Invalid CSRF Token or Code not returned');
    }

    return code;
  }
}
