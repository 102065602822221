<div [formGroup]="basicInfoForm" class="pb-8">
  <h3 class="text-white text-xl mb-4 mt-4">Game information</h3>

  <div class="mb-4 relative">
    <label for="short-description" class="text-md block text-gray-400 mb-1">
      Short description*
    </label>

    <textarea
      id="short-description"
      formControlName="shortDescription"
      pInputTextarea
      [autoResize]="true"
      class="w-full"
      placeholder="Short description"
      [rows]="5"
      [appValidationMessage]="basicInfoForm.controls['shortDescription']"
      [validationMessages]="{
        required: 'Short description is required',
        pattern: 'Invalid format for short description',
        minlength: 'Minimal length is 50 symbols',
        maxlength: 'Max length is 200 symbols',
      }"
      [autocomplete]="false"
    ></textarea>
  </div>
  <div class="mb-5 relative">
    <label for="full-description" class="text-md block text-gray-400 mb-1">
      Full description*
    </label>
    <div
      class="editor"
      [ngClass]="{
        'editor-invalid':
          basicInfoForm.controls['fullDescription'].invalid &&
          basicInfoForm.controls['fullDescription'].dirty
      }"
      [appValidationMessage]="basicInfoForm.controls['fullDescription']"
      [validationMessages]="{
      required: 'Full description is required',
      pattern: 'Invalid format for full description',
      minlength: 'Minimal length is 150 symbols',
      maxlength: 'Max length is 2300 symbols',
    }"
    >
      <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"> </ngx-editor-menu>
      <ngx-editor
        id="full-description"
        [editor]="editor"
        placeholder="Full Description"
        outputFormat="html"
        formControlName="fullDescription"
      >
      </ngx-editor>
    </div>
  </div>
  <div class="mb-5 relative">
    <label for="genres" class="text-md block text-gray-400 mb-1">Genres*</label>

    <p-listbox
      class="p-listbox-horizontal"
      [multiple]="true"
      [options]="genres"
      formControlName="genres"
      [appValidationMessage]="basicInfoForm.get('genres')"
      [validationMessages]="{
        required: 'At least 1 genre is required',
        maxlength: 'Max amount of genres is 3',
      }"
    />
  </div>

  <div class="mb-5">
    <label for="chains" class="text-md block text-gray-400 mb-1">
      Chains supported
    </label>
    <p-listbox
      class="p-listbox-horizontal"
      [multiple]="true"
      [options]="chains"
      formControlName="chains"
    />
  </div>

  <div class="mb-7 relative flex justify-content-between gap-4">
    <div class="flex-1">
      <label for="release-date" class="text-md block text-gray-400 mb-1">
        Release date
      </label>
      <p-calendar
        formControlName="releaseDate"
        class="p-fluid"
        id="release-date"
        dateFormat="dd.mm.yy"
        placeholder="DD.MM.YYYY"
        [minDate]="MIN_RELEASE_DATE"
        [appValidationMessage]="basicInfoForm.get('releaseDate')"
        [validationMessages]="{
        required: 'Release Date is required',        
      }"
      />
    </div>
    <div class="flex-1">
      <label for="stage" class="text-md block text-gray-400 mb-1">
        Release stage*
      </label>
      <p-dropdown
        [options]="stages"
        optionLabel="label"
        formControlName="releaseStage"
        placeholder="Select a Release Stage"
        class="p-fluid"
      />
    </div>
  </div>

  <h3 class="text-white text-xl mb-4">Media</h3>
  <div class="flex gap-3 mb-6">
    <div>
      <label for="genres" class="text-md block text-gray-400 mb-2">
        Banner
        <span
          class="pi pi-question-circle"
          [pTooltip]="bannerTooltipContent"
          tooltipPosition="top"
          [autoHide]="false"
        ></span>

        <ng-template #bannerTooltipContent>
          Displayed on the games page within the platform.
          <a
            (click)="redirectToDocs()"
            class="text-purple-300 cursor-pointer hover:text-purple-200"
          >
            Go to Docs
          </a>
        </ng-template>
      </label>
      <app-media-upload
        [multiple]="false"
        classNames="border-dotted border-2 h-14rem w-14rem"
        (imagesUploaded)="onBannerSelect($event)"
        [gameId]="gameId"
        [imageType]="IMAGE_TYPE.BANNER"
      >
        @if(!bannerImage) {
        <div class="text-center">
          <span class="pi pi-upload text-2xl block mb-4 text-gray-300"></span>
          <h3 class="text-white m-0 mb-2 text-md">Game banner</h3>
          <p class="line-height-2 m-0 text-gray-400">
            Drag & Drop or <span class="text-primary">Browse</span>
          </p>
        </div>
        } @else {
        <div
          class="relative w-full h-full"
          appRemoveButton
          (imageRemoved)="onRemoveBanner()"
        >
          <img [src]="bannerImage" alt="" class="w-full h-full" />
        </div>

        }
      </app-media-upload>
    </div>

    <div class="flex-1">
      <label for="genres" class="text-md block text-gray-400 mb-2">
        Background
        <span
          class="pi pi-question-circle"
          [pTooltip]="backgroundTooltipContent"
          tooltipPosition="top"
          [autoHide]="false"
        ></span>

        <ng-template #backgroundTooltipContent>
          Used as the background for the game page.
          <a
            (click)="redirectToDocs()"
            class="text-purple-300 cursor-pointer hover:text-purple-200"
          >
            Go to Docs
          </a>
        </ng-template>
      </label>
      <app-media-upload
        classNames="border-dotted border-2 h-14rem"
        (imagesUploaded)="onBackgroundSelect($event)"
        [gameId]="gameId"
        [imageType]="IMAGE_TYPE.BANNER"
      >
        @if(!backgroundImage) {
        <div class="text-center">
          <span class="pi pi-upload text-2xl block mb-4 text-gray-300"></span>
          <h3 class="text-white m-0 mb-2 text-md">Game background</h3>
          <p class="line-height-2 m-0 text-gray-400">
            Drag & Drop or <span class="text-primary">Browse</span>
          </p>
        </div>
        } @else {
        <div
          class="relative w-full h-full"
          appRemoveButton
          (imageRemoved)="onRemoveBackground()"
        >
          <div
            class="bg-cover bg-top w-full h-full"
            [ngStyle]="{
              'background-image': 'url(' + backgroundImage + ')'
            }"
          ></div>
          <div
            class="absolute bottom-0 left-0 w-full h-10rem"
            style="
              background: linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0.7) 100%
              );
            "
          ></div>
        </div>

        }
      </app-media-upload>
    </div>
  </div>

  <div class="mb-5">
    <label for="genres" class="text-md block text-gray-400 mb-2">
      Images
      <span
        class="pi pi-question-circle"
        [pTooltip]="imagesTooltipContent"
        tooltipPosition="top"
        [autoHide]="false"
      ></span>

      <ng-template #imagesTooltipContent>
        Displayed within the game page to showcase the game visuals.
        <a
          (click)="redirectToDocs()"
          class="text-purple-300 cursor-pointer hover:text-purple-200"
        >
          Go to Docs
        </a>
      </ng-template>
    </label>

    <div class="flex flex-wrap gap-2">
      <app-media-upload
        classNames="w-16rem h-9rem border-dotted border-2"
        [multiple]="true"
        (imagesUploaded)="onScreenshotsSelected($event)"
        [gameId]="gameId"
        [imageType]="IMAGE_TYPE.SCREENSHOT"
      >
        <span
          class="pi pi-plus text-2xl absolute-centered text-gray-300"
        ></span>
      </app-media-upload>

      @for (screenshot of screenshots; track $index) {
      <div
        class="relative"
        appRemoveButton
        (imageRemoved)="onRemoveScreenshot($index)"
      >
        <img
          [src]="screenshot"
          [alt]="'Screenshot' + $index"
          class="h-9rem"
          [ngStyle]="{
            'aspect-ratio': '16 / 9',
            'object-fit': 'cover',
            'object-position': 'top'
          }"
        />
      </div>
      }
    </div>
  </div>
</div>
