import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import {
  DynamicDialogConfig,
  DynamicDialogModule,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { AuthService } from '../../services/auth.service';
import { SIGNUP_TYPE } from '../../../tools/constants/auth.constants';
import { GoogleAuthService } from '../../services/google-auth.service';
import { DiscordAuthService } from '../../services/discord-auth.service';
import { ValidationMessageDirective } from '../../directives/validation-message.directive';
import { TEXT } from '../../../tools/constants/pattern.constants';

@Component({
  selector: 'app-basic-info-dialog',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ValidationMessageDirective,
    ButtonModule,
    DialogModule,
    DropdownModule,
    DynamicDialogModule,
    InputTextModule,
  ],
  templateUrl: './basic-info-dialog.component.html',
})
export class BasicInfoDialogComponent {
  form: FormGroup = this.fb.group({
    username: ['', [Validators.required, Validators.pattern(TEXT)]],
    developerTag: ['', [Validators.required, Validators.pattern(TEXT)]],
    registeredAddress: ['', [Validators.required, Validators.pattern(TEXT)]],
  });

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private fb: FormBuilder,
    private authService: AuthService,
    private googleAuthService: GoogleAuthService,
    private discordAuthService: DiscordAuthService
  ) {
    this.config.header = 'Add your basic information';
    this.config.contentStyle = {
      width: '100vh',
      'max-width': '448px',
      'background-color': '#18181E',
    };
  }

  get signupData(): {
    email?: string;
    password?: string;
    type: SIGNUP_TYPE;
  } {
    return this.config.data;
  }

  onSubmit(): void {
    const { username, developerTag, registeredAddress } = this.form.value;

    switch (this.signupData.type) {
      case SIGNUP_TYPE.BASIC: {
        const password: string = this.signupData.password ?? '';
        const email: string = this.signupData.email ?? '';

        this.authService.basicSignUp({
          password,
          email,
          username,
          developerTag,
          registeredAddress,
        });
        break;
      }
      case SIGNUP_TYPE.GOOGLE: {
        localStorage.setItem('socialAuthAction', 'signup');
        localStorage.setItem(
          'signupData',
          JSON.stringify({ username, developerTag, registeredAddress })
        );

        this.googleAuthService.handleGoogleAuthorization();

        break;
      }
      case SIGNUP_TYPE.DISCORD: {
        localStorage.setItem('socialAuthAction', 'signup');
        localStorage.setItem(
          'signupData',
          JSON.stringify({ username, developerTag, registeredAddress })
        );

        this.discordAuthService.handleDiscordAuthorization();

        break;
      }
      default: {
        console.error('Unknown signup type');
      }
    }

    this.ref.close();
  }
}
