import { NgFor, NgIf, NgStyle } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { PanelModule } from 'primeng/panel';
import { TabViewModule } from 'primeng/tabview';
import { ActivatedRoute, Router } from '@angular/router';
import { TagModule } from 'primeng/tag';
import { DialogService } from 'primeng/dynamicdialog';
import { RejectGameComponentDialog } from '../../../shared/modals/reject-game-dialog/reject-game-dialog.component';
import { RevisionsRequiredGameDialogComponent } from '../../../shared/modals/revisions-required-game-dialog/revisions-required-game-dialog.component';
import { AdminService } from '../../../shared/services/admin.service';
import { GameData } from '../../../tools/interfaces/response.interfaces';
import { GAME_STATUS } from '../../../tools/constants/game.constants';
import { ConvertEnumFromBEPipe } from '../../../shared/pipes/convert-enum-from-be.pipe';
import { switchMap, tap } from 'rxjs';
import { GamesService } from '../../../shared/services/games.service';
import { ImageModule } from 'primeng/image';
import { NOTIFICATION } from '../../../tools/constants/notification.constants';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-admin-game',
  standalone: true,
  imports: [
    NgStyle,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    TabViewModule,
    PanelModule,
    InputTextModule,
    AvatarModule,
    AvatarGroupModule,
    TagModule,
    ImageModule,
    ConvertEnumFromBEPipe,
    NgIf,
    NgFor
  ],
  templateUrl: './admin-game.component.html',
})
export class AdminGameComponent implements OnInit {
  gameData: GameData | null | undefined = null;
  GAME_STATUS = GAME_STATUS;
  id = 0;
  downloadLink = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private adminService: AdminService,
    private gameService: GamesService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      const id = params.get('game-id');

      if (id) {
        this.id = +id;

        this.adminService.getGamesForReview([]).subscribe((games) => {
          this.gameData = games.find((game) => game.id === +id);
        });

        this.gameService
          .getGameDownloadLink(+id)
          .subscribe((res) => (this.downloadLink = res.url));
      }
    });
  }

  onRedirect(link: string | undefined) {
    if (link) {
      window.open(link, '_blank');
    }
  }

  downloadGame() {
    window.location.href = this.downloadLink;
  }

  openRejectModal() {
    this.dialogService.open(RejectGameComponentDialog, {
      data: {
        rejectGame: this.rejectGame.bind(this),
      },
    });
  }

  openReviewRequiredModal() {
    this.dialogService.open(RevisionsRequiredGameDialogComponent, {
      data: {
        requestRevisions: this.requestRevisions.bind(this),
      },
    });
  }

  requestRevisions(feedback: string) {
    return this.adminService
      .requestRevisionsForGame(this.id)
      .pipe(
        tap((res) => {
          this.gameData = res;
        }),
        switchMap(() => this.adminService.sendFeedback(this.id, feedback))
      )
      .subscribe((res) => console.log(res));
  }

  rejectGame() {
    return this.adminService.rejectGame(this.id).subscribe((res) => {
      this.gameData = res;
    });
  }

  approveGame() {
    return this.adminService.approveGame(this.id).subscribe((res) => {
      this.gameData = res;
    });
  }

  publishGame() {
    return this.adminService.publishGame(this.id).subscribe((res) => {
      this.gameData = res;
    });
  }

  unpublishGame() {
    return this.adminService.unpublishGame(this.id).subscribe((res) => {
      this.gameData = res;
    });
  }

  deleteGame() {
    return this.adminService.deleteGame(this.id).subscribe(() => {
      this.router.navigateByUrl('/admin/gameslist');

      this.messageService.add({
        severity: NOTIFICATION.SUCCESS,
        summary: 'Success',
        detail: 'The game has been successfully deleted',
      });
    })
  }
}
