<form
  [formGroup]="signupForm"
  (ngSubmit)="onBasicSignup()"
  class="justify-self-center w-400px h-full align-self-end m-auto"
>
  <div class="mb-6">
    <h2 class="text-4xl mb-2 font-medium">Create an account</h2>
    <span class="text-md text-gray-400 ml-1">I have an account.</span>
    <a
      routerLink="/auth/login"
      class="text-primary text-md font-normal no-underline hover:text-primary-300"
    >
      Sign in
    </a>
  </div>

  <div class="mb-3 relative">
    <label for="email" class="block mb-1 text-md text-gray-200">Email</label>
    <input
      id="email"
      type="email"
      placeholder="Enter your email"
      pInputText
      class="w-full autofill-main-black"
      formControlName="email"
      [appValidationMessage]="signupForm.get('email')"
      [validationMessages]="{
        required: 'Email is required',
        email: 'Invalid format for Email'
      }"
    />
  </div>

  <div class="mb-4 relative">
    <label for="password" class="block mb-1 text-md text-gray-200">
      Password
    </label>
    <p-password
      id="password"
      class="w-full p-fluid"
      placeholder="Enter your password"
      formControlName="password"
      [feedback]="false"
      [toggleMask]="true"
      [appValidationMessage]="signupForm.get('password')"
      [validationMessages]="{
        required: 'Password is required',
        pattern: 'Invalid format for password'
      }"
    />
  </div>

  <div class="flex gap-2 mb-4">
    <p-checkbox
      [binary]="true"
      inputId="user-consent"
      formControlName="userConsent"
    />
    <p class="text-gray-400 text-md">
      By signing up, you agree to our
      <a
        class="text-primary font-normal no-underline hover:text-primary-300 cursor-pointer"
        (click)="onOpenPrivacyPolicy($event)"
      >
        Privacy Policy
      </a>
      and
      <a
        class="text-primary font-normal no-underline hover:text-primary-300 cursor-pointer"
        (click)="onOpenLicenseAgreement($event)"
      >
        End User License Agreement (EULA)
      </a>
    </p>
  </div>

  <p-button
    type="submit"
    label="Sign up"
    [disabled]="signupForm.invalid"
    class="p-fluid mb-5 block"
  ></p-button>

  <span class="text-gray-400 flex gap-3 align-items-center mb-5 text-md">
    <hr class="border-none border-top-1 border-gray-700 flex-1" />
    <span>Or continue with</span>
    <hr class="border-none border-top-1 border-gray-700 flex-1" />
  </span>

  <div class="flex gap-2">
    <p-button
      severity="secondary"
      [disabled]="!isUserConsentGiven"
      class="p-fluid w-full"
      (onClick)="onGoogleSignup()"
    >
      <div class="flex align-items-center justify-content-center w-full gap-3">
        <img src="../../../assets/icons/social/google.svg" alt="" />

        <span class="font-bold">Google</span>
      </div>
    </p-button>
    <p-button
      severity="secondary"
      [disabled]="!isUserConsentGiven"
      class="p-fluid w-full"
      (onClick)="onDiscordSignup()"
    >
      <div class="flex align-items-center justify-content-center w-full gap-3">
        <img src="../../../assets/icons/social/discord.svg" alt="" />

        <span class="font-bold">Discord</span>
      </div>
    </p-button>
  </div>
</form>
