import { NgClass, NgFor, NgStyle } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../../../shared/services/admin.service';
import { TagModule } from 'primeng/tag';
import { GameData } from '../../../tools/interfaces/response.interfaces';
import { GAME_STATUS } from '../../../tools/constants/game.constants';
import { ConvertEnumFromBEPipe } from '../../../shared/pipes/convert-enum-from-be.pipe';
import { PaginatorModule, PaginatorState } from 'primeng/paginator';

@Component({
  selector: 'app-admin-panel',
  standalone: true,
  imports: [
    NgClass,
    NgStyle,
    NgFor,
    TagModule,
    ConvertEnumFromBEPipe,
    PaginatorModule,
  ],
  styles: [
    `
      .pagination ::ng-deep .ngx-pagination {
        display: flex;
        justify-content: center;
        gap: 4px;
        padding: 0;
      }

      .pagination ::ng-deep .ngx-pagination .ng-star-inserted {
        border-radius: 4px;
        font-size: 14px;
        line-height: 157%;
        color: #5e636d;
        width: 28px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #18181e;
      }

      .pagination ::ng-deep .ngx-pagination .ng-star-inserted:after,
      .pagination ::ng-deep .ngx-pagination .ng-star-inserted:before {
        display: none;
      }

      .pagination ::ng-deep .ngx-pagination .current {
        background-color: #776bf8;
      }

      .pagination ::ng-deep .ngx-pagination .current span {
        color: #fff;
        background-color: transparent;
      }
    `,
  ],
  templateUrl: './admin-panel.component.html',
})
export class AdminPanelComponent implements OnInit {
  GAME_STATUS: typeof GAME_STATUS = GAME_STATUS;
  games: GameData[] = [];
  paginatedGames: GameData[] = [];
  rows: number = 10;
  totalRecords: number = 0;
  currentPage: number = 0;

  constructor(private router: Router, private adminService: AdminService) {}

  ngOnInit(): void {
    this.adminService.getGamesForReview([]).subscribe((res) => {
      this.games = res;
      this.totalRecords = this.games.length;
      this.onPaginate({ first: 0, rows: this.rows });
    });
  }

  onPaginate(event: PaginatorState): void {
    this.currentPage = event.page ?? 0;
    const start: number = event.first ?? 0;
    const end: number = start + (event.rows ?? 0);
    this.paginatedGames = this.games.slice(start, end);
  }

  onGameClick(gameId: number) {
    this.router.navigateByUrl(`/admin/gameslist/${gameId}`);
  }
}
