export enum GAME_STATUS {
  DRAFT = 'DRAFT',
  REVIEW = 'REVIEW',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  PUBLISHED = 'PUBLISHED',
  REVISIONS_REQUIRED = 'REVISIONS_REQUIRED',
  UNPUBLISHED = 'UNPUBLISHED',
  UNPUBLISH_REQUESTED = 'UNPUBLISH_REQUESTED',
  PUBLISH_REQUESTED = 'PUBLISH_REQUESTED',
}

export enum GAME_GENRES {
  ACTION = 'action',
  ADVENTURE = 'adventure',
  RPG = 'RPG',
  STRATEGY = 'strategy',
  SPORTS = 'sports',
  MMORPG = 'MMORPG',
  RACING = 'racing',
  FIGHTING = 'fighting',
  SHOOTER = 'shooter',
  HORROR = 'horror',
  WEB3 = 'web3',
  SURVIVAL = 'survival',
  BATTLE_ROYALE = 'battle royal',
  MOBA = 'MOBA',
  TOWER_DEFENSE = 'tower defense',
  CARDS = 'cards',
  PLAY_TO_EARN = 'play to earn',
  FANTASY = 'fantasy',
  OPEN_WORLD = 'open world',
  TACTICAL = 'tactical',
  MULTIPLAYER = 'multiplayer',
  SCI_FI = 'sci-fi',
  PVP = 'PvP',
  SIMULATION = 'simulation',
  PUZZLE = 'puzzle',
  METAVERSE = 'metaverse',
  CASUAL = 'casual',
  IDLE = 'idle',
  ESPORTS = 'esports',
  ARCADE = 'arcade',
  AUTO_BATTLER = 'auto battler',
  NFT = 'NFT',
  HARDCORE = 'hardcore'
}


export enum GAME_CHAINS {
  ETHEREUM = 'ethereum',
  BSC = 'BSC',
  SOLANA = 'solana',
  POLYGON = 'polygon',
  HEDERA = 'hedera',
}
