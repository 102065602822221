import { DatePipe, NgFor, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { GameData } from '../../../tools/interfaces/response.interfaces';
import { ConvertEnumFromBEPipe } from '../../pipes/convert-enum-from-be.pipe';

@Component({
  selector: 'app-game-preview-dialog',
  standalone: true,
  imports: [NgIf, NgFor, DatePipe, ConvertEnumFromBEPipe],
  providers: [ConvertEnumFromBEPipe],
  templateUrl: './game-preview-dialog.component.html',
  styleUrl: './game-preview-dialog.component.scss',
})
export class GamePreviewDialogComponent {
  game;

  private get basicInfo(): {
    title: string;
    shortDescription: string;
    fullDescription: string;
    genres: string[];
    chains: string[];
    releaseDate?: Date;
    releaseStage: {
      label: string;
      value: string;
    };
    screenshots: string[];
    bannerImage: string;
    backgroundImage: string;
    developer?: {
      displayName: string;
    };
  } {
    return this.config.data;
  }

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private datePipe: DatePipe,
    private convertEnumFromBEPipe: ConvertEnumFromBEPipe
  ) {
    this.config.width = '80vw';
  
    this.config.contentStyle = {
      'overflow-y': 'scroll',
      'overflow-x': 'hidden',
      padding: '0',
    };
    this.config.header = 'Game page preview';

    this.game = {
      name: this.basicInfo.title,
      pageData: {
        description: this.basicInfo.shortDescription,
        detailedDescription: this.basicInfo.fullDescription,
        screenshots: this.basicInfo.screenshots,
        background: this.basicInfo.backgroundImage,

        details: [
          {
            title: 'Developers',
            content:
              this.basicInfo.developer?.displayName ?? 'Unknown Developer',
          },
          {
            title: 'Release',
            content: this.basicInfo?.releaseDate
              ? this.datePipe.transform(
                  this.basicInfo.releaseDate,
                  'dd MMM, yyyy'
                )
              : this.basicInfo.releaseStage.label,
          },
          {
            title: this.basicInfo?.chains?.length ? 'Chains' : '',
            content: this.basicInfo?.chains?.length
              ? this.basicInfo?.chains
                  ?.map((chain) => chain[0].toUpperCase() + chain.slice(1))
                  ?.join(', ')
              : [],
          },
        ],
        version: false,
      },
      isInBeta: false,
      genres: this.basicInfo.genres,
    };
  }

  onClose(): void {
    this.ref.close();
  }
}
