import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { PasswordModule } from 'primeng/password';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { HttpClientModule } from '@angular/common/http';
import { GoogleAuthService } from '../../shared/services/google-auth.service';
import { DiscordAuthService } from '../../shared/services/discord-auth.service';
import { PASSWORD, TEXT } from '../../tools/constants/pattern.constants';
import { NgStyle } from '@angular/common';
import { ValidationMessageDirective } from '../../shared/directives/validation-message.directive';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  standalone: true,
  imports: [
    NgStyle,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ValidationMessageDirective,
    PasswordModule,
    ButtonModule,
    InputTextModule,
    HttpClientModule,
  ],
})
export class SignInComponent {
  signinForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    public authService: AuthService,
    public googleAuthService: GoogleAuthService,
    public discordAuthService: DiscordAuthService
  ) {
    this.signinForm = this.fb.group({
      emailOrUsername: [null, [Validators.required, Validators.pattern(TEXT)]],
      password: [null, [Validators.required, Validators.pattern(PASSWORD)]],
    });
  }

  googleSocialSignin(): void {
    localStorage.setItem('socialAuthAction', 'signin');
    this.googleAuthService.handleGoogleAuthorization();
  }

  discordSocialSignin(): void {
    localStorage.setItem('socialAuthAction', 'signin');
    this.discordAuthService.handleDiscordAuthorization();
  }

  onSubmit(): void {
    if (this.signinForm.valid) {
      this.authService.basicLogin(this.signinForm.value);
    } else {
      console.log('Form is invalid');
    }
  }
}
