import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { GAME_STATUS } from '../../tools/constants/game.constants';
import { GameData } from '../../tools/interfaces/response.interfaces';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  private gamesByStatusSubject: BehaviorSubject<GameData[]> =
    new BehaviorSubject<GameData[]>([]);

  get gamesForReview(): GameData[] {
    return this.gamesByStatusSubject.value;
  }

  constructor(private http: HttpClient) {}

  getGamesForReview(statuses: GAME_STATUS[]): Observable<GameData[]> {
    let params = new HttpParams();
    const statusesForRequest =
      statuses.length === 0
        ? [
            GAME_STATUS.REVISIONS_REQUIRED,
            GAME_STATUS.REJECTED,
            GAME_STATUS.APPROVED,
            GAME_STATUS.REVIEW,
            GAME_STATUS.PUBLISH_REQUESTED,
            GAME_STATUS.UNPUBLISH_REQUESTED,
            GAME_STATUS.PUBLISHED,
          ]
        : statuses;

    statusesForRequest.forEach((status) => {
      params = params.append(
        'status',
        status.split(' ').join('_').toUpperCase()
      );
    });

    return this.http
      .get<GameData[]>(`${environment.devportalApi}/api/game/review`, {
        params,
      })
      .pipe(tap((games: GameData[]) => this.gamesByStatusSubject.next(games)));
  }

  getGameById(id: number) {
    return this.gamesForReview.find((game) => game.id === id);
  }

  approveGame(id: number) {
    return this.http.put<GameData>(
      `${environment.devportalApi}/api/game/status/review/result`,
      {
        gameId: id,
        status: GAME_STATUS.APPROVED,
      }
    );
  }

  rejectGame(id: number) {
    return this.http.put<GameData>(
      `${environment.devportalApi}/api/game/status/review/result`,
      {
        gameId: id,
        status: GAME_STATUS.REJECTED,
      }
    );
  }

  requestRevisionsForGame(id: number) {
    return this.http.put<GameData>(
      `${environment.devportalApi}/api/game/status/review/result`,
      {
        gameId: id,
        status: GAME_STATUS.REVISIONS_REQUIRED,
      }
    );
  }

  sendFeedback(id: number, feedback: string) {
    return this.http.put(
      `${environment.devportalApi}/api/game/review/feedback`,
      {
        gameId: id,
        review: feedback,
      }
    );
  }

  publishGame(id: number) {
    return this.http.put<GameData>(
      `${environment.devportalApi}/api/game/status/publish/${id}`,
      {}
    );
  }

  unpublishGame(id: number) {
    return this.http.put<GameData>(
      `${environment.devportalApi}/api/game/status/unpublish/${id}`,
      {}
    );
  }

  deleteGame(id: number) {
    return this.http.delete<GameData>(
      `${environment.devportalApi}/api/game/delete/${id}`,
      {}
    );
  }
}
