import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { tap } from 'rxjs';

export const AdminGuard: CanActivateFn = () => {
  const authService: AuthService = inject(AuthService);
  const router: Router = inject(Router);

  return authService.isAdmin$.pipe(
    tap((isAdmin: boolean) => {
      if (!isAdmin) {
        router.navigateByUrl('/auth/login');
      }
    })
  );
};
