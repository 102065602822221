import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DiscordAuthService {
  private readonly CLIENT_ID: string = '1273572342370402387';
  private readonly REDIRECT_URI: string = `${environment.devportalUrl}/login/oauth2/code/discord`;
  private readonly SCOPES: string[] = ['identify', 'email', 'openid'];

  constructor(private http: HttpClient, private router: Router) {}
  private generateCsrfToken(): string {
    return Math.random().toString(36).substring(2);
  }

  handleDiscordAuthorization(): void {
    const csrfToken: string = this.generateCsrfToken();

    const authUrl: string =
      `https://discord.com/api/oauth2/authorize?` +
      new HttpParams({
        fromObject: {
          client_id: this.CLIENT_ID,
          redirect_uri: this.REDIRECT_URI,
          response_type: 'code',
          scope: this.SCOPES.join(' '),
          state: csrfToken,
        },
      }).toString();

    sessionStorage.setItem('discordCsrfToken', csrfToken);

    window.location.href = authUrl;
  }

  handleRedirectCallback(): string {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    const returnedCsrfToken = params.get('state');
    const storedCsrfToken = sessionStorage.getItem('discordCsrfToken');

    // sessionStorage.removeItem('discordCsrfToken');

    if (!code || returnedCsrfToken !== storedCsrfToken) {
      throw new Error('Invalid CSRF Token or Code not returned');
    }

    return code;
  }
}
