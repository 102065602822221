import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoaderComponent } from '../../../shared/components/loader/loader.component';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styles: [
    `
      // $container-padding: 20px;

      .container {
        // padding: $container-padding;
      }

      .right-panel {
        // height: calc(100vh - 2 * $container-padding);
      }
    `,
  ],
  standalone: true,
  imports: [RouterModule, LoaderComponent],
})
export class AuthLayoutComponent {}
