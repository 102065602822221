import { Component } from '@angular/core';
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { BasicInfoDialogComponent } from '../../shared/modals/basic-info-dialog/basic-info-dialog.component';
import { AuthService } from '../../shared/services/auth.service';

import { SIGNUP_TYPE } from '../../tools/constants/auth.constants';
import { ValidationMessageDirective } from '../../shared/directives/validation-message.directive';
import { PASSWORD } from '../../tools/constants/pattern.constants';
import { CheckboxModule } from 'primeng/checkbox';
import { PrivacyPolicyDialogComponent } from '../../shared/modals/privacy-policy-dialog/privacy-policy-dialog.component';
import { LicenseAgreementDialogComponent } from '../../shared/modals/license-agreement-dialog/license-agreement-dialog.component';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ValidationMessageDirective,
    PasswordModule,
    ButtonModule,
    InputTextModule,
    DialogModule,
    DropdownModule,
    DynamicDialogModule,
    CheckboxModule,
  ],
})
export class SignUpComponent {
  signupForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private dialogService: DialogService,
    public authService: AuthService
  ) {
    this.signupForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.pattern(PASSWORD)]],
      userConsent: [false, [Validators.requiredTrue]],
    });
  }

  onOpenPrivacyPolicy(event: MouseEvent): void {
    event.preventDefault();
    this.dialogService.open(PrivacyPolicyDialogComponent, {});
  }

  onOpenLicenseAgreement(event: MouseEvent): void {
    event.preventDefault();
    this.dialogService.open(LicenseAgreementDialogComponent, {});
  }

  get isUserConsentGiven(): boolean {
    return this.signupForm.get('userConsent')?.value;
  }

  onBasicSignup(): void {
    if (this.signupForm.invalid) return;

    const { password, email } = this.signupForm.value;
    this.dialogService.open(BasicInfoDialogComponent, {
      data: {
        password,
        email,
        type: SIGNUP_TYPE.BASIC,
      },
    });
  }

  onGoogleSignup(): void {
    this.dialogService.open(BasicInfoDialogComponent, {
      data: { type: SIGNUP_TYPE.GOOGLE },
    });
  }

  onDiscordSignup(): void {
    this.dialogService.open(BasicInfoDialogComponent, {
      data: { type: SIGNUP_TYPE.DISCORD },
    });
  }
}
