<form [formGroup]="newPasswordForm">
  <div class="mb-4 mt-5">
    <label for="current-password" class="block mb-1 text-md text-gray-200">
      Current password
    </label>
    <p-password
      id="current-password"
      placeholder="Enter your current password"
      formControlName="currentPassword"
      [toggleMask]="true"
      [feedback]="false"
      class="p-fluid"
    ></p-password>
  </div>
  <div class="mb-4">
    <label for="new-password" class="block mb-1 text-md text-gray-200">
      New password
    </label>
    <p-password
      id="new-password"
      placeholder="Enter your new password"
      formControlName="newPassword"
      [toggleMask]="true"
      [feedback]="false"
      class="p-fluid"
    ></p-password>
  </div>
  <div class="mb-6">
    <label for="repeat-new-password" class="block mb-1 text-md text-gray-200">
      Repeat new password
    </label>
    <p-password
      id="repeat-new-password"
      placeholder="Repeat your new password"
      formControlName="repeatNewPassword"
      [toggleMask]="true"
      [feedback]="false"
      class="p-fluid"
    ></p-password>
  </div>

  <div class="flex justify-content-end gap-2">
    <p-button
      label="Cancel"
      (onClick)="onCancel()"
      severity="secondary"
    ></p-button>
    <p-button
      label="Change password"
      [disabled]="!(newPasswordForm.valid && doPasswordsMatch)"
      (onClick)="onUpdatePassword()"
    ></p-button>
  </div>
</form>
