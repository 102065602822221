import { Component, Input, NgZone, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormsModule,
  NgModel,
  ReactiveFormsModule,
} from '@angular/forms';
import { ChipsModule } from 'primeng/chips';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PanelModule } from 'primeng/panel';
import { TabMenuModule } from 'primeng/tabmenu';
import { MediaUploadComponent } from '../../../shared/components/media-upload/media-upload.component';
import { NgClass, NgStyle } from '@angular/common';
import {
  GAME_CHAINS,
  GAME_GENRES,
} from '../../../tools/constants/game.constants';
import { ListboxModule } from 'primeng/listbox';
import { CalendarModule } from 'primeng/calendar';
import { ValidationMessageDirective } from '../../../shared/directives/validation-message.directive';
import { Editor, NgxEditorModule, Toolbar } from 'ngx-editor';
import { RemoveButtonDirective } from '../../../shared/directives/remove-button.directive';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';
import { Router, RouterModule } from '@angular/router';
import { IMAGE_TYPE } from '../../../tools/constants/image.constants';

@Component({
  selector: 'app-basic-info',
  standalone: true,
  imports: [
    NgStyle,
    NgClass,
    ValidationMessageDirective,
    ListboxModule,
    MediaUploadComponent,
    FormsModule,
    ReactiveFormsModule,
    PanelModule,
    TabMenuModule,
    InputTextModule,
    InputTextareaModule,
    ChipsModule,
    CalendarModule,
    RemoveButtonDirective,
    FormsModule,
    NgxEditorModule,
    DropdownModule,
    TooltipModule,
    RouterModule,
  ],
  templateUrl: './basic-info.component.html',
})
export class BasicInfoComponent {
  @Input() basicInfoForm!: FormGroup;
  @Input() gameId!: number;

  IMAGE_TYPE = IMAGE_TYPE;

  editor!: Editor;
  toolbar!: Toolbar;

  readonly genres: GAME_GENRES[] = Object.values(GAME_GENRES);
  readonly chains: GAME_CHAINS[] = Object.values(GAME_CHAINS);
  readonly stages: { label: string; value: string }[] = [
    { value: 'TBD', label: 'TBD' },
    { value: 'BETA', label: 'Beta' },
    { value: 'ALPHA', label: 'Alpha' },
    { value: 'EARLY_ACCESS', label: 'Early Access' },
    { value: 'RELEASED', label: 'Released' },
  ];
  readonly MIN_RELEASE_DATE: Date = new Date(2000, 0, 1);

  constructor(private router: Router, private ngZone: NgZone) {
    this.initializeTextEditor();
  }

  get screenshots(): string[] {
    return this.basicInfoForm.get('screenshots')?.value ?? [];
  }

  get bannerImage(): string {
    return this.basicInfoForm.get('bannerImage')?.value ?? '';
  }
  get backgroundImage(): string {
    return this.basicInfoForm.get('backgroundImage')?.value ?? '';
  }

  onBannerSelect(event: string[]): void {
    const [banner] = event;
    this.basicInfoForm.patchValue({
      bannerImage: banner,
    });
  }

  onBackgroundSelect(event: string[]): void {
    const [background] = event;

    this.basicInfoForm.patchValue({
      backgroundImage: background,
    });
  }

  onScreenshotsSelected(addedScreenshots: string[]): void {
    const previousScreenshots = this.screenshots;
    this.basicInfoForm.patchValue({
      screenshots: [...previousScreenshots, ...addedScreenshots],
    });
  }

  onRemoveBackground(): void {
    this.basicInfoForm.patchValue({
      backgroundImage: '',
    });
  }

  onRemoveBanner(): void {
    this.basicInfoForm.patchValue({
      bannerImage: '',
    });
  }

  onRemoveScreenshot(index: number): void {
    const patchedScreenshots: string[] = this.screenshots.slice();
    patchedScreenshots.splice(index, 1);

    this.basicInfoForm.patchValue({
      screenshots: patchedScreenshots,
    });
  }

  redirectToDocs(): void {
    this.ngZone.run(() => {
      this.router.navigate(['/app/documentation/gettingstarted'], {
        fragment: 'images',
      });
    });
  }

  private initializeTextEditor(): void {
    this.editor = new Editor();
    this.toolbar = [
      ['bold', 'italic'],
      ['ordered_list', 'bullet_list'],
      [{ heading: ['h2', 'h3', 'h4'] }],
    ];
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }
}
