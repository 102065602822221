<form
  [formGroup]="restorePasswordForm"
  (ngSubmit)="onSubmit()"
  class="form w-400px m-auto"
>
  <a
    routerLink="/auth/login"
    class="text-sm border-1 border-gray-700 p-2 border-round-lg text-white no-underline mb-6 block w-fit hover:bg-gray-800"
  >
    <span class="pi pi-chevron-left text-sm"></span>
    Back
  </a>

  <h2 class="text-4xl mb-6 font-medium">Forgot password</h2>

  <div class="mb-4 relative">
    <label for="email" class="block mb-1 text-md text-gray-200">Email</label>
    <input
      id="email"
      type="email"
      placeholder="Enter your email"
      pInputText
      formControlName="email"
      class="autofill-main-black w-full"
      [appValidationMessage]="restorePasswordForm.get('email')"
      [validationMessages]="{
        email: 'Invalid format for Email',
        required: 'Email is required',
      }"
    />
  </div>

  <span class="text-gray-400 block mb-5 text-md"
    >We will send a new password to your email</span
  >

  <p-button
    type="submit"
    label="Send new password"
    [disabled]="restorePasswordForm.invalid"
    class="p-fluid"
  ></p-button>
</form>
